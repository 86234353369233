import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSpinner, FaCopy } from "react-icons/fa6";
import { IoTimeSharp } from "react-icons/io5";

function Home() {
  const [originalUrl, setOriginalUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [totalLinks, setTotalLinks] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);
  const [showing, setShowing] = useState(false);
  const [generatedLinks, setGeneratedLinks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setShowing(true);
      try {
        const response = await axios.get("https://r.notreal003.xyz/api/links");
        setTotalLinks(response.data.length);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Could not retrieve link data.");
        setTimeout(() => setError(""), 10000);
      } finally {
        setShowing(false);
      }
    };

    fetchData();
    const savedLinks = JSON.parse(localStorage.getItem("generatedLinks")) || [];
    setGeneratedLinks(savedLinks);
  }, []);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleShorten = async (e) => {
    e.preventDefault();
    if (!originalUrl || !isValidUrl(originalUrl)) {
      setError("Please enter a valid URL.");
      setTimeout(() => setError(""), 4000);
      return;
    }

    setLoading(true);
    setError("");
    setShortUrl("");

    try {
      const response = await axios.post("https://r.notreal003.xyz/api/url/shorten", {
        originalUrl,
      });
      const newShortUrl = response.data.shortUrl;
      setShortUrl(newShortUrl);

      const updatedLinks = [
        { originalUrl, shortUrl: newShortUrl, createdAt: new Date().toLocaleString() },
        ...generatedLinks,
      ];
      setGeneratedLinks(updatedLinks);
      localStorage.setItem("generatedLinks", JSON.stringify(updatedLinks));
      setOriginalUrl("");
    } catch (error) {
      setError("Failed to shorten the URL. Please try again.");
      setTimeout(() => setError(""), 4000);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClear = () => {
    setOriginalUrl("");
    setError("");
    setShortUrl("");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900 text-white p-4">
      <div className="max-w-md w-full p-6 bg-gradient-to-br from-gray-800 to-gray-700 rounded-lg shadow-2xl">
        <h1 className="text-4xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 animate-pulse">
          Link Shortener
        </h1>

        <div className="mb-6 text-center">
          <p className="text-lg font-medium">
            Total Links Created:{" "}
            {showing ? (
              <FaSpinner className="inline animate-spin text-blue-500" />
            ) : (
              <span className="text-blue-400 font-bold">{totalLinks}</span>
            )}
          </p>
          {error && (
            <div className="alert alert-error mt-2 bg-red-600 text-white rounded-lg p-3 animate-bounce">
              <span className="text-sm">{error}</span>
            </div>
          )}
        </div>

        <form onSubmit={handleShorten} className="flex flex-col gap-4">
          <div className="relative">
            <input
              type="text"
              value={originalUrl}
              onChange={(e) => setOriginalUrl(e.target.value)}
              required
              className={`w-full px-4 py-3 bg-transparent border-2 rounded-lg text-white placeholder-gray-400 focus:outline-none transition-all duration-200 ${
                isValidUrl(originalUrl) ? "border-green-500" : "border-gray-600"
              }`}
              placeholder="https://example.com"
              aria-label="Original URL"
            />
            {originalUrl && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-300"
                aria-label="Clear URL"
              >
                <IoTimeSharp />
              </button>
            )}
          </div>
          <button
            type="submit"
            className={`w-full px-4 py-3 rounded-lg font-semibold transition-all ${
              loading ? "bg-gray-600 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700 transform hover:scale-105"
            }`}
            disabled={loading}
            aria-label="Shorten URL"
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <FaSpinner className="animate-spin w-5 h-5 mr-2" />
                Shortening...
              </div>
            ) : (
              "Shorten URL"
            )}
          </button>
        </form>

        {shortUrl && (
          <div className="alert alert-success mt-6 p-4 rounded-lg text-center bg-green-600 text-white shadow-lg transition-all transform hover:scale-105">
            <p className="font-semibold">Shortened URL:</p>
            <div className="flex items-center justify-center gap-2 mt-2">
              <a
                href={shortUrl}
                className="text-white font-bold hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {shortUrl}
              </a>
              <button onClick={() => handleCopy(shortUrl)} className="text-green-300 transition-all duration-200 hover:text-green-500" aria-label="Copy shortened URL">
                {copied ? "Copied!" : <FaCopy />}
              </button>
            </div>
          </div>
        )}

        {generatedLinks.length > 0 && (
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-center mb-4">Your Generated Links</h2>
            <ul className="space-y-3 animate-fadeIn">
              {generatedLinks.map((link, index) => (
                <li key={index} className="p-4 bg-gray-700 rounded-lg text-center transition-all duration-200 transform hover:scale-105">
                  <a
                    href={link.shortUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 font-medium hover:underline"
                  >
                    {link.shortUrl}
                  </a>
                  <p className="text-gray-400 text-sm">{link.originalUrl}</p>
                  <p className="text-gray-500 text-xs">{link.createdAt}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
